<template>
  <div>
    <ModalEditChannelLanguages
      v-if="showEditLanguages"
      :showModal="showEditLanguages"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditLanguages">
      <template v-slot:title>Languages</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-hoverable">
      <tbody>
        <tr v-for="(language, index) in channel.Languages" :key="index">
          <td>{{ $t('LanguageLabels.Language' + language.LanguageId) }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditChannelLanguages = () =>
  import('@/components/Settings/ModalEditChannelLanguages')

export default {
  components: {
    ModalEditChannelLanguages,
  },

  props: {},

  data() {
    return {
      languages: [],
      showEditLanguages: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.languages.push({ Id: 52, Name: 'Dutch' })
    this.languages.push({ Id: 65, Name: 'English' })
    this.languages.push({ Id: 86, Name: 'German' })
    this.languages.push({ Id: 75, Name: 'French' })
    this.languages.push({ Id: 156, Name: 'Spanish' })
    this.languages.push({ Id: 106, Name: 'Italian' })
  },

  methods: {
    getLanguageName(languageId) {
      let index = this.languages.findIndex((l) => l.Id === languageId)
      if (index > -1) {
        return this.languages[index].Name
      }
      return ''
    },

    setShowEditLanguages(meetingtype) {
      this.showEditLanguages = true
    },

    hideModal() {
      this.showEditLanguages = false
    },
  },
}
</script>

<style></style>
